import { useState, useEffect, FC } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { cover } from '$lib/style-helpers';

const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
};

export const PageTransition: FC = () => {
    const [showTransition, setUseTransition] = useState(false);
    const router = useRouter();

    useEffect(() => {
        const handleChangeStart = (url: string) => {
            if (router.asPath !== url) {
                setUseTransition(true);
            }
        };
        const handleChangeCompleted = () => setUseTransition(false);
        router.events.on('routeChangeStart', handleChangeStart);
        router.events.on('routeChangeComplete', handleChangeCompleted);

        return () => {
            router.events.off('routeChangeStart', handleChangeStart);
            router.events.off('routeChangeComplete', handleChangeCompleted);
        };
    }, []);

    return <Transition animate={showTransition ? 'open' : 'closed'} variants={variants} />;
};

const Transition = styled(motion.div)(({ theme }) => ({
    ...cover,
    pointerEvents: 'none',
    zIndex: theme.zIndices.loadingSpinner,
    backgroundColor: theme.colors.white,
}));
