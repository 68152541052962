import React, { FC } from 'react';
import { useRouter } from 'next/router';
import { ifProp } from 'styled-tools';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Flex } from '$components/layouts';
import { mq } from '$lib/helpers';
import { Link } from '../link';
import { key } from '~/utils';

type Props = {
    breadcrumbs?: { key: string; value: string }[];
};

export const Breadcrumbs: FC<Props> = ({ breadcrumbs }: Props) => {
    const { asPath } = useRouter();
    const theme = useTheme();

    return breadcrumbs?.length ? (
        <Flex alignItems="center">
            {breadcrumbs.map((breadcrumb, i, arr) => (
                <Breadcrumb
                    hiddenOnMobile={i < arr.length - 2} //only show last two to prevent overflow
                    themedLink={theme.colors.grey40}
                    key={key(breadcrumb)}
                    href={breadcrumb.key !== asPath ? breadcrumb.key : undefined}
                >
                    {breadcrumb.value}
                </Breadcrumb>
            ))}
        </Flex>
    ) : (
        <></>
    );
};

const Breadcrumb = styled(Link)<{ hiddenOnMobile: boolean }>(
    ({ theme }) => ({
        padding: `${theme.space[2]} ${theme.space[2]} ${theme.space[5]} 0`,
        color: theme.colors.grey40,
        fontWeight: theme.fontWeights.medium,
        fontSize: theme.fontSizes.sm,
        ':not(:last-child)': {
            ':after': {
                paddingLeft: theme.space[2],
                content: '"/"',
            },
        },

        [mq('md')]: {
            padding: `${theme.space[5]} ${theme.space[2]} ${theme.space[5]} 0`,
        },
    }),
    ifProp(
        { hiddenOnMobile: true },
        {
            [mq(0, 'sm')]: {
                display: 'none',
            },
        }
    )
);
