import { B2CDeliveryMethod } from '$constants';
import { IBreadCrumb, IEnrichedProduct } from '$models';
import { IMAGE_ID_URL, WEB_URL } from './urls.helper';

const getAvailability = (selectedProduct: IEnrichedProduct) => {
    switch (selectedProduct.b2CDeliveryMethod) {
        case B2CDeliveryMethod.ShopOnly:
        case B2CDeliveryMethod.ShopAndDirect:
        case B2CDeliveryMethod.Download:
        case B2CDeliveryMethod.DirectOnly:
            return selectedProduct.isReleased ? 'http://schema.org/InStock' : 'https://schema.org/PreOrder';
        case B2CDeliveryMethod.NotPossible:
            return 'https://schema.org/BackOrder';
        case B2CDeliveryMethod.PreorderDirectOnly:
        case B2CDeliveryMethod.PreorderShopOnly:
        case B2CDeliveryMethod.PreorderShopAndDirect:
            return 'https://schema.org/PreOrder';
        default:
            return 'http://schema.org/InStock';
    }
};

export const convertStructuredProduct = (selectedProduct?: IEnrichedProduct) => {
    if (!selectedProduct) {
        return null;
    }
    const imageId = selectedProduct?.primaryImageUrl?.split('?i=')?.[1];

    return {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: selectedProduct.name || null,
        description: selectedProduct.product?.description ? selectedProduct.product.description : null,
        // Short description?
        // description: selectedProduct.product?.shortDescription
        //     ? selectedProduct.product.shortDescription
        //     : selectedProduct.product?.description
        //     ? selectedProduct.product.description
        //     : null,
        productID: selectedProduct.product?.itemNumber || null,
        brand: selectedProduct.product?.productBrand || null,
        category: selectedProduct.product?.itemGroupName || null,
        url: selectedProduct.productUrlAbsolute || null,
        sku: selectedProduct.product?.itemEanCode || null,
        image: imageId ? `${IMAGE_ID_URL}/${imageId}/jpg/0/720/${imageId}.jpg` : null,
        aggregateRating:
            (selectedProduct.product?.itemTotalNumberOfReviews || 0) > 0
                ? {
                      '@type': 'AggregateRating',
                      ratingValue: `${((selectedProduct.product?.itemReviewScore || 0) / 100) * 5}`,
                      reviewCount: `${selectedProduct.product?.itemTotalNumberOfReviews || 0}`,
                  }
                : null,
        offers: {
            '@type': 'Offer',
            availability: getAvailability(selectedProduct),
            price: `${selectedProduct.activePrice?.amount}` || null,
            priceCurrency: selectedProduct.activePrice?.currency || null,
            url: selectedProduct.productUrlAbsolute || null,
            // Currently not usable, since the data shows the endtime as year 9999
            // priceValidUntil: selectedProduct.inActivePrice ? formatISODateToString(selectedProduct.activePrice?.toDateTime) || null  : null
        },
    };
};

export const convertStructuredBreadcrumb = (currentPageName: string, breadCrumbs?: IBreadCrumb[]) => {
    if (!breadCrumbs) {
        return null;
    }
    const convertedBreadcrumbs = breadCrumbs.map((breadCrumb, index): Record<string, unknown> => {
        return {
            '@type': 'ListItem',
            position: `${index + 1}`,
            name: breadCrumb.value,
            item: `${WEB_URL}${breadCrumb.key}`,
        };
    });
    convertedBreadcrumbs.push({
        '@type': 'ListItem',
        position: `${breadCrumbs.length + 1}`,
        name: currentPageName,
    });
    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: convertedBreadcrumbs,
    };
};
