import React, { useState, useEffect, FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { motion, Variants } from 'framer-motion';
import { cover } from '$lib/style-helpers';
import { useNavigate } from '$hooks';
import { useSite } from '~/store';
import {
    contentVariants,
    onSamePathVariants,
    spinnerVariants,
} from '$components/elements/page-transition/page-transition.animation';
import { Loading } from '../loading';

export const PageTransitionContent: FC<{ children: ReactNode }> = ({ children }) => {
    const [showTransition, setUseTransition] = useState(false);
    const [transitionVariants, setTransitionVariants] = useState<Variants>(contentVariants);
    const { navigateStart, url } = useNavigate();
    const { setSite } = useSite();

    // We do not want to transition when navigating under the same path
    // we check the first paths and eject if those are alike
    const getVariants = (previous: string, current: string): Variants => {
        const [, prevPath] = previous.split('/');
        const [, currPath] = current.split('/');

        if (prevPath === currPath) {
            return onSamePathVariants;
        }

        return contentVariants;
    };

    useEffect(() => {
        const variants = getVariants(window.location.pathname, url);
        setTransitionVariants(variants);
        setUseTransition(navigateStart);
        setSite({ inPageTransition: navigateStart });
    }, [navigateStart]);

    return (
        <>
            <motion.div initial="visible" animate={showTransition ? 'hidden' : 'visible'} variants={transitionVariants}>
                {children}
            </motion.div>
            <SpinnerContainer
                initial="hidden"
                animate={showTransition ? 'visible' : 'hidden'}
                variants={spinnerVariants}
            >
                <Loading />
            </SpinnerContainer>
        </>
    );
};

const SpinnerContainer = styled(motion.div)(({ theme }) => ({
    ...cover,
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
    zIndex: theme.zIndices.loadingSpinner,
}));
