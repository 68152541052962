import { FC } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Container, Flex, Grid } from '$components/layouts';
import { brandLeg } from '$constants/env';
import { mq } from '$lib/helpers';
import { IFooterMenuItem, IPaymentIPaymentMethod } from '$models';
import { key } from '~/utils/key';
import { Accordion } from '../accordion';
import { Link } from '../link';
import { Logo } from '../logo';
import { PaymentIcons } from '../payment-icons/payment-icons';
import { Responsive } from '../responsive';
import { Text } from '../text';

type Props = {
    columns?: IFooterMenuItem[];
    paymentMethods?: IPaymentIPaymentMethod[];
    isCheckout?: boolean;
};

export const Footer: FC<Props> = ({ columns, paymentMethods, isCheckout = false }: Props) => {
    const theme = useTheme();

    const renderFooterBottom = () => (
        <FooterBottom>
            {/* WHY SO HARDCODED? Because IND-7680 */}
            <FooterLogoAndText>
                <Logo />
                <Flex gap="small">
                    <Text>&#169; Copyright {brandLeg ? 'Legekæden' : 'Bog & idé'}</Text>
                    <Text color={theme.colors.grey15}>|</Text>
                    <Link href="/kundeservice/cookies">Cookiepolitik</Link>
                </Flex>
            </FooterLogoAndText>
            {/* WHY SO HARDCODED? Because IND-7680 */}
            <CO2Neutral
                href={
                    brandLeg
                        ? 'https://www.ingenco2.dk/certificate/6933/da'
                        : 'https://www.ingenco2.dk/crt/dispcust/c/5609/l/2'
                }
            >
                <img src="/assets/co2_neutral.png" width="87" height="40" alt="CO2 neutralt website" />
            </CO2Neutral>
            <PaymentIcons paymentIcons={paymentMethods ?? []} iconHeight={24} iconWidth={40} gap={12} />
        </FooterBottom>
    );

    if (isCheckout) {
        return <FooterContentContainer isCheckout>{renderFooterBottom()}</FooterContentContainer>;
    }

    return (
        <StyledFooter>
            <FooterContentContainer>
                <Responsive min="frame">
                    <FooterColumns flow="column" rowGap="small" columnGap="small" columns="repeat(4, 1fr)">
                        {columns?.map((column) => (
                            <FooterColumn key={key(column)}>
                                <ColumnHeader href={column.url} target={column.urlTarget}>
                                    {column.text}
                                </ColumnHeader>
                                {column.children?.map((columnLink) => (
                                    <ColumnLink
                                        href={columnLink.url}
                                        target={columnLink.urlTarget}
                                        key={key(columnLink)}
                                    >
                                        {columnLink.text}
                                    </ColumnLink>
                                ))}
                            </FooterColumn>
                        ))}
                    </FooterColumns>
                </Responsive>
                <Responsive max="frame">
                    <FooterColumnsMobile>
                        {columns?.map((column) => (
                            <Accordion
                                key={key(column)}
                                withChevron
                                withSeparator
                                withPadding={12}
                                header={
                                    <MobileHeader justifyContent="space-between" alignItems="center">
                                        {column.text}
                                    </MobileHeader>
                                }
                            >
                                <MobileBody column>
                                    {column.children?.map((columnLink) => (
                                        <ColumnLink
                                            key={key(columnLink)}
                                            href={columnLink.url}
                                            target={columnLink.urlTarget}
                                        >
                                            {columnLink.text}
                                        </ColumnLink>
                                    ))}
                                </MobileBody>
                            </Accordion>
                        ))}
                    </FooterColumnsMobile>
                </Responsive>
                {renderFooterBottom()}
            </FooterContentContainer>
        </StyledFooter>
    );
};

const FooterContentContainer = styled(Container)<{ isCheckout?: boolean }>(({ theme, isCheckout = false }) => ({
    display: 'flex',
    borderTop: isCheckout ? 'none' : `4px solid ${theme.colors.black}`,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '28px',
    paddingTop: theme.space[6],

    [mq('frame')]: {
        paddingTop: theme.space[10],
    },
}));

const StyledFooter = styled.footer({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    [mq('frame')]: {
        height: '500px',
    },
});

const FooterColumns = styled(Grid)({
    flex: 1,
});

const FooterColumnsMobile = styled.div(({ theme }) => ({
    flex: 1,
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.fontWeights.medium,
}));

const FooterColumn = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.fontWeights.medium,
    lineHeight: '18px',
}));

const ColumnHeader = styled(Text)(({ theme }) => ({
    ...theme.mixins.useTextStyle('footer'),
    marginBottom: theme.space[3],
    textTransform: 'uppercase',
}));

const MobileHeader = styled(Flex)(({ theme }) => ({
    color: theme.colors.textDefault,
    fontSize: theme.fontSizes.xxxs,
    fontWeight: theme.fontWeights.bold,
    textTransform: 'uppercase',
}));

const ColumnLink = styled(Link)(({ theme }) => ({
    marginBottom: theme.space[4],

    '&:first-child': {
        marginTop: theme.space[2],
    },
}));

const MobileBody = styled(Flex)(({ theme }) => ({
    color: theme.colors.textDefault,
    fontSize: theme.fontSizes.sm,
}));

const FooterBottom = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
    color: theme.colors.grey,
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.medium,
    position: 'relative',
    width: '100%',
    marginTop: theme.space[8],

    [mq(0, 'ml')]: {
        '& > *:not(:first-child)': {
            marginBottom: theme.space[7],
        },
    },

    [mq('ml')]: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.space[3],
    },
}));

const FooterLogoAndText = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: theme.colors.grey40,
    flex: '1 1 1px',

    '* > span': {
        display: 'block',
        whiteSpace: 'nowrap',
    },

    img: {
        [mq(0, 'ml')]: {
            marginBottom: theme.space[4],
        },
    },

    [mq('ml')]: {
        gap: theme.space[4],
        flexDirection: 'row',
        alignItems: 'center',
        ':first-child': {
            marginBottom: 0,
        },
    },
}));

const CO2Neutral = styled(Link)({
    [mq('ml')]: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
});
