import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Container, Flex } from '$components/layouts';
import { useTranslation } from '$hooks';
import { Text } from '$components/elements/text';
import { Responsive } from '$components/elements/responsive';
import { Logo } from '$components/elements/logo';

export const CheckoutNavigation: FC = () => {
    const { translate } = useTranslation();
    const theme = useTheme();

    return (
        <header>
            <Responsive max="frame">
                <MobileMenuContainer justifyContent="center" alignItems="center" spacingBottom="medium">
                    <Logo />
                </MobileMenuContainer>
            </Responsive>
            <Responsive min="frame">
                <ScrollProgressBar />
                <Container>
                    <MenuWrapper>
                        <Logo size="large" />
                        <Flex column alignItems="flex-end">
                            <Text fontWeight="Bold" size={theme.fontSizes.sm}>
                                {translate('checkout.checkoutNavigation.checkoutHelpHeader')}
                            </Text>
                            <Text size={theme.fontSizes.sm}>
                                {translate('checkout.checkoutNavigation.checkoutHelpBody')}
                            </Text>
                        </Flex>
                    </MenuWrapper>
                </Container>
            </Responsive>
        </header>
    );
};

const MobileMenuContainer = styled(Flex)(({ theme: { space } }) => ({
    position: 'relative',
    padding: `${space[4]} ${space[3]} 0`,
}));

const MenuWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.space[9]} 0 ${theme.space[6]}`,
}));

const ScrollProgressBar = styled.div(({ theme }) => ({
    width: '100%',
    height: '6px',
    background: theme.colors.black,
}));
