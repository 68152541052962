import React, { FC, ReactNode } from 'react';
import * as RadixAccordion from '@radix-ui/react-accordion';
import styled from '@emotion/styled';
import { keyframes, useTheme } from '@emotion/react';
import { SvgIcon } from '../svg-icon';
import { Flex } from '$components/layouts';

type Props = {
    header?: ReactNode | string;
    withChevron?: boolean;
    withPadding?: boolean | number;
    iconColor?: string;
    withSeparator?: boolean;
    separatorColor?: string;
    onAccordionChange?: (isOpen: boolean) => void;
    expansiveWidth?: boolean;
    bordered?: boolean;
};

export const Accordion: FC<Props> = (props) => {
    const theme = useTheme();

    const {
        header,
        withChevron,
        withPadding,
        iconColor = theme.colors.black,
        withSeparator,
        separatorColor = theme.colors.borderColor,
        onAccordionChange,
        expansiveWidth = true,
        bordered,
        children,
    } = props;

    return (
        <RadixAccordion.Root type="single" collapsible onValueChange={(value) => onAccordionChange?.(!!value)}>
            <RadixAccordion.Item value="item-1">
                <AccordionHeader>
                    <AccordionHeaderWrapper
                        withSeparator={withSeparator}
                        separatorColor={separatorColor}
                        bordered={bordered}
                    >
                        <AccordionTrigger
                            chevron={withChevron ? 1 : 0}
                            padding={withPadding ? (typeof withPadding === 'number' ? withPadding : 1) : 0}
                            expansive={expansiveWidth ? 1 : 0}
                        >
                            {header}
                            {!!withChevron && (
                                <AccordionChevronContainer>
                                    <AccordionChevron svg="chevronDown" color={iconColor} size={24} aria-hidden />
                                </AccordionChevronContainer>
                            )}
                        </AccordionTrigger>
                    </AccordionHeaderWrapper>
                </AccordionHeader>
                <AccordionContent>{children}</AccordionContent>
            </RadixAccordion.Item>
        </RadixAccordion.Root>
    );
};

const AccordionHeader = styled(RadixAccordion.Header)({
    margin: 0,
    display: 'flex',
});

const AccordionTrigger = styled(RadixAccordion.Trigger)<{ chevron?: number; padding?: number; expansive?: number }>(
    ({ chevron, padding, expansive = false }) => ({
        position: 'relative',
        backgroundColor: 'transparent',
        border: 'none',
        padding: typeof padding === 'number' ? `${padding}px 0` : padding ? '20px 0' : 0,
        display: 'flex',
        alignItems: 'center',
        width: expansive ? '100%' : 'auto',
        cursor: 'pointer',
        paddingRight: chevron ? '30px' : 0,
    })
);

const AccordionHeaderWrapper = styled(Flex)<{
    withSeparator?: boolean;
    separatorColor?: string;
    bordered?: boolean;
}>(({ withSeparator, separatorColor, bordered, theme }) => ({
    width: '100%',
    ...(withSeparator && {
        borderBottom: withSeparator ? `1px solid ${separatorColor}` : 'none',

        '* > [data-state=open] &': {
            borderBottom: 'none',
        },
    }),
    ...(bordered && {
        borderBottom: theme.general.border,
        borderTop: theme.general.border,
        padding: `${theme.space[3]} 0`,
        marginBottom: theme.space[2],
    }),
}));

const slideDown = keyframes({
    from: { height: 0 },
    to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
    from: { height: 'var(--radix-accordion-content-height)' },
    to: { height: 0 },
});

const AccordionChevronContainer = styled(Flex)({
    height: '100%',
});

const AccordionChevron = styled(SvgIcon)({
    position: 'absolute',
    right: 0,
    transition: 'transform 200ms',

    '[data-state=open] &': {
        transform: 'rotate(180deg)',
    },
    '[data-state=closed] &': {
        transform: 'rotate(0deg)',
    },
});

const AccordionContent = styled(RadixAccordion.Content)({
    overflow: 'hidden',

    '&[data-state="open"]': {
        animation: `${slideDown} 200ms ease-out`,
    },
    '&[data-state="closed"]': {
        animation: `${slideUp} 200ms ease-out`,
    },
});
