import { FC } from 'react';
import Head from 'next/head';

type Props = {
    jsonData: Record<string, unknown> | null;
    structuredDataKey: string;
};

export const StructuredData: FC<Props> = ({ jsonData, structuredDataKey }): JSX.Element => {
    if (!jsonData) {
        return <></>;
    }
    return (
        <Head>
            <script
                key={structuredDataKey}
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonData) }}
            />
        </Head>
    );
};
