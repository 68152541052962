import React, { FC } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { brandLeg } from '$constants/env';

type Size = 'small' | 'large';

type Props = {
    size?: Size;
};

export const Logo: FC<Props> = (props) => {
    const { size, ...rest } = props;
    const height = brandLeg ? (size === 'large' ? 48 : 25) : size === 'large' ? 31 : 25;
    const width = brandLeg ? (size === 'large' ? 210 : 109) : size === 'large' ? 116 : 94;

    return (
        <Link href="/">
            <LogoImage
                src={brandLeg ? '/assets/legekaeden.svg' : '/assets/bog-og-ide.png'}
                alt="logo"
                height={height}
                width={width}
                {...rest}
            />
        </Link>
    );
};

const LogoImage = styled.img({
    maxWidth: '100%',
    height: 'auto',
    cursor: 'pointer',
});
